<template>
  <section class="app-main">
    <!-- <transition name="fade" mode="out-in"> -->
    <!-- 加上这个属性左导就不会进行缓存了 :exclude="exclude", 组件内必须要加name -->
    <!-- gles不使用keep-alive -->
    <!-- <keep-alive :exclude="keepAliveExcludeList"> -->
    <router-view v-if="isRouterAlive" :key="key" />
    <!-- </keep-alive> -->
    <!-- iframe页 -->
    <component :is="item.name" v-for="item in hasOpenComponentsArr" v-show="$route.name === item.name" :key="item.name" />
    <!-- </transition> -->
  </section>
</template>

<script>
/**
 * 标签功能：
 * 1. 主动刷新缓存方案；
 * 2. 左侧菜单刷新页面、标签页不刷新；
 * 3. 来自标签唤醒需要缓存，iframe也需要缓存；
 *
 * 标签功能影响：
 * 1. 会缓存页面
 * 2.
 */
import Vue from 'vue'
import eventBus from '@/libs_sz/utils/eventBus'
import { mapState } from 'vuex'

export default {
  name: 'AppMain',
  data() {
    return {
      keepAliveExcludeList: [],
      componentsArr: [],
      isRouterAlive: true,
      whiteList: ['addPalletGroup']
    }
  },
  computed: {
    ...mapState('tagsView', ['visitedViews']),
    key() {
      return this.$route.path
    },
    // 实现懒加载，只渲染已经打开过（hasOpen:true）的iframe页
    hasOpenComponentsArr() {
      return this.componentsArr.filter((item) => item.hasOpen)
    }
  },
  watch: {
    // TODO 性能可能有点问题， 即
    $route(to, from) {
      // === 此逻辑暂未使用，先注销 ====
      // 不包含fresh，如果已经打开的页面， 等于doRefresh操作；
      if (!to.query.keepalive) {
        // 重新打开不缓存地图了
        this.changeOpenIframePage()
        this.noCache()
      }
      this.$nextTick(() => this.isOpenIframePage())
    }
  },
  created() {
    eventBus.$on('doRefresh', this.doRefresh)
    // 设置iframe页的数组对象
    const componentsArr = this.getComponentsArr()
    componentsArr.forEach((item) => {
      Vue.component(item.name, item.component)
    })
    this.componentsArr = componentsArr
    // 判断当前路由是否iframe页
    this.isOpenIframePage()
  },
  methods: {
    // 不缓存的页面，自动从右侧打开。
    noCache() {
      const { whiteList } = this
      const visitedViews = this.$store.state.tagsView.visitedViews
      const isOpen = visitedViews.findIndex((i) => i.name === this.$route.name)
      if (whiteList.includes(this.$route.name)) {
        this.doRefresh(false)
      } else if (isOpen === -1) {
        this.$store.commit('tagsView/DEL_VISITED_VIEWS', this.$route)
        this.$store.commit('tagsView/ADD_VISITED_VIEWS', this.$route)
        this.doRefresh(false)
      }
    },
    doRefresh(isNeedLoading = true) {
      isNeedLoading && this.$store.commit('IS_LOADING', true)
      this.changeOpenIframePage()
      const routerName = this.$route.name
      this.keepAliveExcludeList.push(routerName)
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
        const index = this.keepAliveExcludeList.findIndex((i) => i === routerName)
        this.keepAliveExcludeList.splice(index, 1)
        this.isOpenIframePage()
        isNeedLoading && this.$store.commit('IS_LOADING', false)
      })
    },
    // 重新刷新对应的
    changeOpenIframePage(value = false) {
      const target = this.componentsArr.find((item) => {
        return item.name === this.$route.name
      })
      if (target && target.hasOpen) {
        target.hasOpen = false
      }
    },
    // 根据当前路由设置hasOpen
    isOpenIframePage() {
      const target = this.componentsArr.find((item) => {
        return item.name === this.$route.name
      })
      if (target && !target.hasOpen) {
        target.hasOpen = true
      }
    },
    // 获取所有
    getComponentsArr() {
      const router = this.$router
      const routes = router.options.routes.find((i) => i.path === '/').children
      const iframeArr = routes.filter((item) => item.iframeComponent)

      return iframeArr.map((item) => {
        const name = item.name || item.path.replace('/', '')
        return {
          name,
          path: item.path,
          hasOpen: false, // 是否打开过，默认false
          component: item.iframeComponent // 组件文件的引用
        }
      })
    }
  }
}
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.app-main {
  height: 100%;
  padding: 0;
}

.fixed-header + .app-main {
  padding-top: 50px;
}

.hasTagsView {
  .app-main {
    /* 84 = navbar + tags-view = 50 + 34 */
    min-height: calc(100vh - 84px);
  }

  .fixed-header + .app-main {
    padding-top: 84px;
  }
}
</style>
