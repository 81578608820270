<template>
  <div class="main">
    <!-- Layout -->
    <el-container class="main-container">
      <el-aside v-if="showMenu" class="css-aside">
        <m-menu :menu-data="menuData" :is-collapse="menuExpand" />
      </el-aside>
      <el-main class="ui-manage">
        <div class="manage-nav">
          <NavBar :show-menu="showMenu" />
        </div>
        <tags-view />
        <div class="main-content">
          <app-main />
        </div>
      </el-main>
      <!-- <m-advice :visible.sync="toolsVisible" /> -->
    </el-container>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MMenu from './components/MMenu/MMenu'
import AppMain from './components/AppMain.vue'
import TagsView from './components/TagsView'
import NavBar from './components/NavBar'

export default {
  name: 'Layout',
  components: {
    MMenu,
    NavBar,
    AppMain,
    TagsView
  },
  data() {
    return {
      msg: '',
      dataReady: false,
      toolsVisible: false,
      isRouterAlive: true
    }
  },
  computed: {
    ...mapState(['routeInfo', 'showMenu', 'menuExpand', 'menuData']),
    ...mapGetters(['stores']),
    active() {
      // 暂不考虑children
      const { name } = this.routeInfo
      return name
    }
  },
  created() {},
  methods: {}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.main {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.main .manage-nav {
  flex: 0 1 60px;
}
.el-container {
  background: #fbfbfb;
  height: 100%;
}
.el-menu-vertical-demo {
  min-height: 100%;
}
.css-aside {
  width: auto !important;
  min-height: 100vh;
  overflow: initial;
  transition: all 0.2s;
}
/* .el-menu {
  border-right: 0;
} */
.main .el-menu {
  min-width: 200px;
}
.main .el-menu--collapse {
  width: 64px !important;
  min-width: auto;
}
.el-main {
  padding: 0;
}
.shadow {
  box-shadow: -2px 2px 12px 0px rgba(76, 76, 76, 0.09);
}
.ui-manage {
  flex-direction: column;
  background: #f0f2f5;
  overflow: hidden;
  display: flex;
  height: 100%;
}
.ge-tools {
  position: fixed;
  bottom: 50px;
  right: 0;
  z-index: 10;
  background: red;
}

.ge-tools .ge-tools--item {
  width: 70px;
  height: 40px;
  background: #999;
  text-align: center;
  line-height: 40px;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}

.main-content {
  overflow: auto;
  position: relative;
  flex: 1;
}
</style>
<style lang="scss">
.el-aside {
  // 侧边栏折叠动画速度
  transition: width 0.15s;
}
</style>
