<template>
  <div class="tags-view-container">
    <div ref="scrollPane" class="tags-view-wrapper">
      <i v-if="isScroll" class="el-icon-arrow-left iconw30" @click="scrollLeft" />
      <div ref="scrollBoxPane" class="scrollBoxPane">
        <div class="scrollBoxPaneBox" :style="`transform: translateX(-${translateX}px);`">
          <!-- :to="{ name: tag.name, path: tag.path, query: { keepalive: true } }" -->
          <span
            v-for="tag in Array.from(visitedViews)"
            ref="tag"
            :key="tag.path"
            class="tags-view-item"
            :class="isActive(tag) ? 'active' : ''"
            @click="toUrl({ name: tag.name, path: tag.path, query: { keepalive: true } })"
          >
            {{ $t(tag.title) }}
            <span class="el-icon-close" @click.prevent.stop="closeSelectedTag(tag)" />
          </span>
        </div>
      </div>
      <i v-if="isScroll" class="el-icon-arrow-right iconw30" @click="scrollRight" />
      <el-dropdown class="dropdownBox" @command="handleCommand">
        <span class="el-dropdown-link">
          <i class="el-icon-arrow-down" />
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item icon="el-icon-back" command="closeLeftTags">
            {{ $t('lang.gles.common.closeLeftTabs') }}
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-right" command="closeRightTags">
            {{ $t('lang.gles.common.closeRightTabs') }}
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-close" command="closeOthersTags">
            {{ $t('lang.gles.common.closeOtherTabs') }}
          </el-dropdown-item>
          <el-dropdown-item icon="el-icon-error" command="closeAllTags">
            {{ $t('lang.gles.common.closeAllTabs') }}
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
// 标签唤起都是keep-alive = true

import { mapState } from 'vuex'

export default {
  components: {},
  data() {
    return {
      // visible: false,
      isScroll: false,
      translateX: 0,
      translateOffset: 140,
      top: 0,
      left: 0,
      isTransition: false
    }
  },
  computed: {
    ...mapState(['menuData', 'menuExpand']),
    visitedViews() {
      return this.$store.state.tagsView.visitedViews
    },
    selectedTag() {
      return Array.from(this.visitedViews).find((item) => this.isActive(item)) || {}
    }
  },
  watch: {
    $route() {
      // 如果是
      this.addViewTags()
      this.moveToCurrentTag()
    },
    visitedViews() {
      this.$nextTick(() => this.checkScrollWidth())
    },
    // visible(value) {
    //   if (value) {
    //     document.body.addEventListener("click", this.closeMenu);
    //   } else {
    //     document.body.removeEventListener("click", this.closeMenu);
    //   }
    // },
    isTransition(value) {
      value &&
        setTimeout(() => {
          this.isTransition = false
        }, 500)
    }
  },
  mounted() {
    this.addViewTags()
  },
  methods: {
    generateRoute() {
      if (this.$route.name) {
        return this.$route
      }
      return false
    },
    isActive(route) {
      return route.path === this.$route.path || route.name === this.$route.name
    },
    addViewTags() {
      const route = this.generateRoute()
      const { menuData } = this

      if (!route) {
        return false
      }

      const menuItem = menuData.find(({ path }) => path === route.path)
      if (!menuItem) {
        return false
      }

      route.meta.title = menuItem.title
      route.meta.keepalive = true
      this.$store.dispatch('tagsView/addVisitedViews', route)
      return true
    },
    moveToCurrentTag() {
      const tags = this.$refs.tag || {}
      this.$nextTick(() => {
        Object.keys(tags).forEach(key => {
          const tag = tags[key]
          if (tag.to === this.$route.path) {
            this.$refs.scrollPane.moveToTarget(tag.$el)
          }
        })
      })
    },
    closeSelectedTag(view) {
      this.$store.dispatch('tagsView/delVisitedViews', view).then((views) => {
        if (this.isActive(view)) {
          const latestView = views.slice(-1)[0]
          if (latestView) {
            this.$router.push(latestView.path)
          } else {
            this.$router.push('/')
          }
        }
      })
    },
    closeOthersTags() {
      this.$router.push(this.selectedTag.path)
      this.$store.dispatch('tagsView/delOthersViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeAllTags() {
      this.$store.dispatch('tagsView/delAllViews')
      this.$router.push('/')
    },
    closeLeftTags() {
      this.$router.push(this.selectedTag.path)
      this.$store.dispatch('tagsView/delLeftViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    closeRightTags() {
      this.$router.push(this.selectedTag.path)
      this.$store.dispatch('tagsView/delRightViews', this.selectedTag).then(() => {
        this.moveToCurrentTag()
      })
    },
    // openMenu(tag, e) {
    //   this.visible = true;
    //   this.selectedTag = tag;
    //   this.left = e.clientX - 100;
    //   this.top = e.clientY;
    // },
    // closeMenu() {
    //   this.visible = false;
    // },
    // 检查滚动条
    checkScrollWidth() {
      const { scrollBoxPane } = this.$refs
      this.isScroll = scrollBoxPane.scrollWidth > scrollBoxPane.clientWidth
      this.translateX = 0
    },
    scrollLeft() {
      // 如果在动画中 则不生效
      if (this.isTransition) {
        return
      }
      const { translateOffset, translateX } = this

      if (translateX <= 0) {
        this.translateX = 0
      } else {
        this.translateX = translateX - translateOffset
      }
      this.isTransition = true
    },
    scrollRight() {
      // 如果在动画中 则不生效
      if (this.isTransition) {
        return
      }
      const { translateOffset, translateX } = this
      const { scrollBoxPane } = this.$refs
      const width = scrollBoxPane.scrollWidth - scrollBoxPane.clientWidth + translateX
      const nextX = translateX + translateOffset
      if (translateX >= width) {
        this.translateX = width
      } else {
        this.translateX = nextX >= width ? width : nextX
      }
      this.isTransition = true
    },
    // 下拉菜单
    handleCommand(command) {
      switch (command) {
        case 'closeOthersTags':
          this.closeOthersTags()
          break
        case 'closeAllTags':
          this.closeAllTags()
          break
        case 'closeLeftTags':
          this.closeLeftTags()
          break
        case 'closeRightTags':
          this.closeRightTags()
          break
        default:
          break
      }
    },
    toUrl(data) {
      this.$router.push(data)
    }
  }
}
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.tags-view-container {
  background: #f0f2f5;
  height: 45px;
  .tags-view-wrapper {
    position: relative;
    height: 43px;
    line-height: 54px;
    display: flex;
    flex-direction: row;
    //border-bottom: 1px solid #d8dce5;
    //box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .12), 0 0 3px 0 rgba(0, 0, 0, .04);

    .scrollBoxPane {
      flex: 1;
      overflow: hidden;
      .scrollBoxPaneBox {
        white-space: nowrap;
        transition: transform 0.5s ease-in-out;
      }
    }

    .tags-view-item {
      height: 32px;
      line-height: 32px;
      background: #fff;
      border-radius: 3px;
      border: none;
      margin-right: 6px;
      color: #808695;
      display: inline-block;
      position: relative;
      padding: 0 8px;
      font-size: 12px;
      margin-left: 5px;
      margin-top: 4px;
      cursor: pointer;
      &:first-of-type {
        margin-left: 15px;
      }
      &.active {
        // background-color: #42b983;
        color: #1890ff;
        // border-color: #42b983;
        &::before {
          // content: "";
          background: #fff;
          display: inline-block;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          position: relative;
          margin-right: 2px;
        }
      }
    }

    .dropdownBox {
      background: #fff;
      width: 30px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      margin-top: 13px;
      border-radius: 3px;
      margin-right: 10px;
    }
  }
  .contextmenu {
    margin: 0;
    background: #fff;
    z-index: 100;
    position: absolute;
    list-style-type: none;
    padding: 5px 0;
    border-radius: 4px;
    font-size: 12px;
    font-weight: 400;
    color: #333;
    box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
    li {
      margin: 0;
      padding: 7px 16px;
      cursor: pointer;
      &:hover {
        background: #eee;
      }
    }
  }
}
</style>

<style rel="stylesheet/scss" lang="scss">
//reset element css of el-icon-close
.tags-view-wrapper {
  .tags-view-item {
    .el-icon-close {
      width: 16px;
      height: 16px;
      vertical-align: 2px;
      border-radius: 50%;
      text-align: center;
      transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform-origin: 100% 50%;
      &:before {
        transform: scale(0.6);
        display: inline-block;
        vertical-align: -3px;
      }
      &:hover {
        background-color: #b4bccc;
        color: #fff;
      }
    }
  }
}

.iconw30 {
  width: 30px;
  cursor: pointer;
  text-align: center;
  line-height: 53px;
}
</style>
