<template>
  <el-submenu
    v-if="childrenList.length"
    :index="String(parentMenu.path || parentMenu.id)"
    class="ui-el-submenu"
  >
    <!-- 菜单   -->
    <template slot="title">
      <!-- <i
        v-if="showIcon"
        class="iconfont"
        :class="parentMenu.icon || 'iconSeat'"
      /> -->
      <svg-icon
        v-if="showIcon"
        :icon-class="parentMenu.icon || 'iconSeat'"
      />
      <span>{{ $t(parentMenu.title) }}</span>
    </template>
    <!-- 递归菜单 -->
    <m-menu-item
      v-for="item in childrenList"
      :key="item.id"
      :parent-menu="item"
      :menu="menu"
      :show-icon="false"
    />
  </el-submenu>
  <el-menu-item v-else :index="parentMenu.path">
    <!-- <i
      v-if="showIcon"
      class="iconfont"
      :class="parentMenu.icon || 'iconSeat'"
    /> -->
    <svg-icon
      v-if="showIcon"
      :icon-class="parentMenu.icon || 'menu-icon-system'"
      style="margin-right:4px;"
    />
    <span slot="title">{{ $t(parentMenu.title) }}</span>
  </el-menu-item>
</template>
<script>
/**
 *  生成当前菜单的item
 *  @props { menu } 全部菜单
 *  @props { parentMenu } 父菜单
 */
export default {
  name: 'MMenuItem',
  props: {
    menu: {
      // 总的菜单数据；
      type: Array,
      default() {
        return []
      }
    },
    parentMenu: {
      type: Object,
      default() {
        return {}
      }
    },
    showIcon: {
      type: Boolean,
      default() {
        return true
      }
    }
  },
  data() {
    return {}
  },
  computed: {
    // 寻找当前菜单的所有子菜单
    childrenList() {
      return this.menu.filter((item) => item.pid === this.parentMenu.id)
    }
  },
  methods: {}
}
</script>
<style lang="scss">
@import '@/assets/scss/variable.scss';
.ui-el-submenu .el-menu {
  background-color: $menu-open-bg;
}

.ui-el-submenu {
  .el-submenu__title {
    display: flex;
    align-items: center;
    .svg-icon {
      font-size: 16px;
      fill: #fff;
      font-weight: 700;
      margin-right: 4px;
    }
  }
}
.iconSeat {
  padding-right: 0 !important;
  // width: 24px;
  // height: 14px;
  // display: inline-block;
}
</style>
